<template>
  <section class="description_outer">
    <div class="container" v-if="product">
      <div class="tab_content">
        <div class="tabs_box" data-type="description">
          <div class="tab" data-id="1" :class="{ active: activeTab === 0}" @click="activeTab = 0">
            <p>Основное описание</p>
          </div>
          <div class="tab" data-id="2"  :class="{ active: activeTab === 1}" @click="activeTab = 1">
            <p>Описание на укр.</p>
          </div>
          <div class="tab" data-id="3"  :class="{ active: activeTab === 2}" @click="activeTab = 2">
            <p>Описание для Instagram</p>
          </div>
          <div class="tab" data-id="4"  :class="{ active: activeTab === 3}" @click="activeTab = 3">
            <p>Для Instagram на укр..</p>
          </div><!-- tab -->
        </div><!-- tabs_box -->
        <div class="tab_container" data-id="1" data-type="description" :class="{ active: activeTab === 0}" ref="description">
          <p v-html="product.descriptions.description"></p>
        </div>
        <div class="tab_container" data-id="2" data-type="description" :class="{ active: activeTab === 1}"  ref="description_ua">
          <p v-html="product.descriptions.description_ua"></p>
        </div>
        <div class="tab_container" data-id="3" data-type="description" :class="{ active: activeTab === 2}" ref="instagram">
          <p v-html="product.descriptions.instagram"></p>
        </div>
        <div class="tab_container" data-id="4" data-type="description" :class="{ active: activeTab === 3}" ref="instagram_ua">
          <p v-html="product.descriptions.instagram_ua"></p>
        </div>
        <button class="btn empty" v-if="copy_text !== '' && isAuth()"
                v-clipboard:copy="copy_text"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
        >
          <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4.85901" y="5.33757" width="7.16675" height="7.20389" rx="1" stroke="#F5A32A"
                  stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path
              d="M2.65387 8.66243H2.10258C1.49364 8.66243 1 8.16623 1 7.55414V2.56683C1 1.95474 1.49364 1.45854 2.10258 1.45854H7.06418C7.67311 1.45854 8.16675 1.95474 8.16675 2.56683V3.12098"
              stroke="#F5A32A" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span>Скопировать описание</span>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ProductInfoBottom',
  inject: ['isAuth'],
  props: {
    product: {
      type: Object || null,
      default: () => {}
    }
  },
  data () {
    return {
      activeTab: 0
    }
  },
  computed: {
    copy_text () {
      let text = ''
      if (this.activeTab === 0 && this.product?.descriptions?.description) {
        text = this.product.descriptions.description
      }

      if (this.activeTab === 1 && this.product?.descriptions?.description_ua) {
        text = this.product.descriptions.description_ua
      }

      if (this.activeTab === 2 && this.product?.descriptions?.instagram) {
        text = this.product.descriptions.instagram
      }

      if (this.activeTab === 3 && this.product?.descriptions?.instagram_ua) {
        text = this.product.descriptions.instagram_ua
      }

      if (text !== null && text !== '') {
        text = text.replaceAll('</p>', '\n')
        text = text.replaceAll('<br>', '\n')
        text = text.replace(/(<([^>]+)>)/ig, '')
      }

      return text
    }
  },
  methods: {
    onCopy () {
      this.$notify({
        type: 'success',
        group: 'notify',
        title: '',
        text: 'Описание скопировано буфер обмена'
      })
    },
    onError () {
      this.$notify({
        type: 'error',
        group: 'notify',
        title: 'Произошла ошибка',
        text: 'Не удалось скопировать текст'
      })
    }
  }
}
</script>

<style scoped>

</style>
