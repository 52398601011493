<template>
  <div class="popup_box size-modal" data-id="list_product" :class="{ active: show }" @click.self="close">
    <div class="popup list_prod" style="width: 800px;max-height: 100%">
      <span class="page_title">Размерные сетки</span>
      <span class="close_popup" @click="close"></span>
      <div>
        <vue-slick-carousel v-bind="settings">
          <div v-for="(imgUrl, i) in images" :key="'xx'+i">
            <img :src="baseUrl+imgUrl" alt="">
          </div>
        </vue-slick-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'SizeImagesModal',
  props: ['images', 'value'],
  components: { VueSlickCarousel },
  data () {
    return {
      baseUrl: null,
      settings: {
        centerMode: true,
        centerPadding: '0',
        lazyLoad: 'ondemand',
        dots: true,
        // fade: true,
        // infinite: true,
        // speed: 300,
        // slidesToShow: 1,
        // slidesToScroll: 1,
        variableHeight: true
      }
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {
    close () {
      this.show = false
    }
  },
  created () {
    this.baseUrl = process.env.VUE_APP_API_URL
  }
}
</script>

<style>
.slick-slide {

}
.slick-slide img {
  max-width: 760px!important;
}
</style>
