<template>
  <div class="product_info_wrapper">
    <product-info-top
        :product="product"
        :color_id.sync="color_id"
        @setShowSizeModal="show_size_modal = true"
    />
    <product-info-bottom :product="product"></product-info-bottom>
    <SizeImagesModal
        v-if="product"
        :images="product.size_images"
        v-model="show_size_modal"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import ProductInfoTop from '../../components/modules/ProductInfo/ProductInfoTop'
import ProductInfoBottom from '../../components/modules/ProductInfo/ProductInfoBottom'
import SizeImagesModal from '../../components/Product/SizeImagesModal'
export default {
  name: 'ProductPage',
  metaInfo () {
    return {
      title: !this.product ? 'Loading...' : this.product.name
    }
  },
  data () {
    return {
      color_id: null,
      show_size_modal: false
    }
  },
  pageClass: 'product-page',
  components: {
    SizeImagesModal,
    ProductInfoTop,
    ProductInfoBottom
  },
  computed: {
    ...mapState('products', ['product'])
  },
  watch: {
    $route: {
      immediate: true,
      handler (val) {
        console.log('set route')
        this.color_id = parseInt(this.$route.query.color_id)
        // if (this.product && this.$route.params.id !== this.product.id) {
        //   this.prepare()
        // }
      }
    },
    color_id: {
      handler (val, old) {
        console.log('set color')
        if (old && val !== old) {
          this.setColorId(parseInt(val))
          this.$router.push({ query: { ...this.$route.query, color_id: parseInt(val) } })
        }
      }
    }
  },
  methods: {
    ...mapActions('products', ['getProduct']),
    ...mapMutations('products', ['setColorId']),
    prepare () {
      console.log('color', parseInt(this.$route.query.color_id))
      this.setColorId(parseInt(this.$route.query.color_id))
      this.getProduct(this.$route.params.id)
      this.$nextTick(() => {
        if (this.$route.query.color_id) {
          this.color_id = parseInt(this.$route.query.color_id)
        }
      })
    }
  },
  created () {
    this.prepare()
  },
  beforeRouteUpdate (to, from, next) {
    this.getProduct(to.params.id)
    next()
  }
}
</script>

<style scoped>

</style>
